import React, {
  useRef,
  useState,
  useContext,
  useImperativeHandle,
  forwardRef,
  ForwardedRef,
  ReactElement
} from 'react';

import {
  useDidUpdateEffect
} from '../hooks/did-update';

import WineFilter from '../components/wine-filter';
import FilterOptions from '../components/filter-options';

import { LocaleContext, getLocalizedValue, LocalizedLabels } from '../components/locale-context';
import { getFilterLabels } from '../queries/get-filter-widgets-labels';
import { WineIdAndTitle } from '../queries/get-wines';

interface SearchBarProps {
  filterText: string,
  emptyLabel: string,
  onChange: (text: string) => void
}

function SearchBar ({ filterText, onChange, emptyLabel }: SearchBarProps): ReactElement {
  return (
    <input
      className = "w3-bar-item w3-right w3-border cpcolor-border-brown1 w3-mobile"
      type = "search"
      placeholder = { `\uD83D\uDD0D ${emptyLabel}` }
      value = { filterText }
      onChange = { (event) => onChange(event.target.value) }
      style = {{ width: '220px' }}
    />
  );
}

const SORTBY = {
  POPULARITY: '0',
  ALPHABETICALLY: '1'
} as const;
type SORTBY = typeof SORTBY[keyof typeof SORTBY];

const SORTBY_OPTIONS = [
  { labelId: 'filteredSectionSortAlphabetically', id: SORTBY.ALPHABETICALLY },
  { labelId: 'filteredSectionSortPopularity', id: SORTBY.POPULARITY }
];

export type RecipeFilterOnChangeCallback = (value: { wineIds: string[], textFilter: string }) => void;
export type RecipeFilterOnSortCallback = (sortValue: SORTBY) => void;

export interface RecipeFiltersImplProps {
  wines: WineIdAndTitle[],
  wakeupValue: { wineIds: string[] },
  onChange: RecipeFilterOnChangeCallback, 
  onSort: RecipeFilterOnSortCallback 
}

export interface RecipeFiltersImplHandlers {
  reset: () => void
}

function RecipeFiltersImpl ({ wines, wakeupValue, onChange, onSort }: RecipeFiltersImplProps, ref: ForwardedRef<RecipeFiltersImplHandlers>): ReactElement {
  const labels = getFilterLabels();
  const { locale } = useContext(LocaleContext);
  const localLabels = getLocalizedValue<LocalizedLabels>(labels, locale);

  const [wineIds, setWineIds] = useState(wakeupValue.wineIds);
  const [textFilter, setTextFilter] = useState('');

  useDidUpdateEffect(() => {
    onChange({
      wineIds,
      textFilter
    });
  }, [ wineIds, textFilter ]);

  const wineFilterRef = useRef<WineFilter>(null);
  const sortRef = useRef<FilterOptions>(null);

  useImperativeHandle(ref, () => ({
    reset: () => {
      if (wineFilterRef.current)
        wineFilterRef.current.reset();
      setTextFilter('');
    }
  }));

  return (
    <div>
      <WineFilter
        wines = { wines }
        selected = { wineIds }
        filterKey = { 'wineIds' }
        onChange = { (values) => setWineIds(values.wineIds) }
        ref = { wineFilterRef }
      />
      <FilterOptions
        label = { localLabels.filteredSectionSort }
        options = { SORTBY_OPTIONS }
        selected = { [ SORTBY.POPULARITY ] }
        filterKey = { 'sort' }
        onChange = { (sortValue) => onSort(sortValue.sort[0] as SORTBY) }
        ref = { sortRef }
        extraWidgets = {
          <SearchBar
            filterText = { textFilter }
            onChange = { (text) => setTextFilter(text) }
            emptyLabel = { localLabels.filteredSectionSearchRecipesBox }
          />
        }
      />
    </div>
  );
}

const RecipeFilters = forwardRef<RecipeFiltersImplHandlers,RecipeFiltersImplProps>(RecipeFiltersImpl);

export { RecipeFilters, SORTBY };
