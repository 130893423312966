import React, { forwardRef, ReactElement } from 'react';

import { graphql } from 'gatsby';

import Layout from '../components/layout';
import { InterractiveSection } from "@chweb/commonui";
import FilteredSection, { FilteredSectionHanlders, FilteredSectionProps } from '../components/filtered-section';

import { localizeUrl } from '../libs/locale';
import { PageSEO } from '../libs/seo';
import { LocationInfo } from '../libs/location';
import { pageInfoByLocale, updateSEO } from '../queries/page-info-helpers';
import { getWineLocalTitle } from '../queries/page-wine-helpers';

import {
  Time
} from '../libs/time';

import RecipeSection, { RecipeSectionProps } from '../components/one-recipe-section';

import queryString from 'query-string';

import {
  SORTBY,
  RecipeFilters,
  RecipeFiltersImplHandlers,
  RecipeFilterOnChangeCallback
} from '../components/recipe-filters';

import {
  RecipeStats
} from '../libs/recipe-stats';

import { getLocalizedValue, labelsByLocale, LocalizedLabels } from '../components/locale-context';
import { WineIdAndTitle } from '../queries/get-wines';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import { WineListData, WineListDataLocales } from '../queries/fragment-wine-list';
import { PageInfoData } from '../queries/fragment-page-info';
import { AllLocalesLabels } from '../queries/fragment-all-locales-labels';
import { WineTitleInfo } from '../libs/wine-title';

interface RecipeData {
    frontmatter: {
        title: string;
        titleimage: {
            childImageSharp: {
                gatsbyImageData: IGatsbyImageData;
            };
        };
        pairing: string[];
        info: {
            prepmins: number;
            mins: number;
            servings: number;
        };
        popularity: number;
    },
    fields: {
      slug: string,
      locale: string
    },
    excerpt: string,
    rawMarkdownBody: string
}

function getRecipes (data: RecipesData, locale: string): RecipeData[] {
  const { recipes, localRecipes } = data;
  const result = recipes.edges.map(r => r.node);

  return result.map(r => {
    const slug = r.fields.slug;
    const local = localRecipes.edges.find(e => {
      const noLocale = e.node.fields.slug.replace(`.${locale}/`, '/');
      return noLocale === slug;
    }) as LocalRecipe;

    return {
      ...r,
      frontmatter: {
        ...r.frontmatter,
        ...local.node.frontmatter
      },
      fields: {
        ...local.node.fields
      },
      excerpt: local.node.excerpt,
      rawMarkdownBody: local.node.rawMarkdownBody
    };
  });
}

interface FilterValue {
  wineIds: string[],
  textFilter: string
}

interface RecipesProps {
  data: RecipesData,
  pageContext: { slug: string, locale: string},
  location: LocationInfo
}

interface RecipesState {
  recipes: RecipeData[] 
}
    
type FilteredSectionSpecifiedProps = FilteredSectionProps<RecipeFiltersImplHandlers, FilterValue>;
const FilteredSectionForwarded = forwardRef<FilteredSectionHanlders, FilteredSectionSpecifiedProps>(FilteredSection);

class Recipes extends React.Component<RecipesProps, RecipesState> {
  private locale: string;
  private recipes: RecipeData[];
  private filteredSectionRef: React.RefObject<FilteredSectionHanlders>;
  private wineChoices: WineIdAndTitle[];
  private labels: LocalizedLabels;
  private seo: PageSEO;

  constructor (props: RecipesProps) {
    super(props);

    this.locale = this.props.pageContext.locale;
    this.renderFilters = this.renderFilters.bind(this);
    this.onSort = this.onSort.bind(this);

    this.recipes = getRecipes(this.props.data, this.locale);

    this.filteredSectionRef = React.createRef<FilteredSectionHanlders>();

    this.wineChoices = this.getWines();

    this.state = {
      recipes: this.onSortHelper(SORTBY.POPULARITY, this.recipes)
    };

    this.labels = labelsByLocale(this.props.data.labels.nodes)[this.locale];

    this.seo = this.getSEO(this.props.location);
  }

  private getWines (): WineIdAndTitle[] {
    const wines = this.props.data.wines.frontmatter.wines;
    return wines.map(w => {
      const localData = w.locales.find(l => l.locale === this.locale) as WineListDataLocales;
      const info = localData.file.childMarkdownRemark.frontmatter;

      const titleInfo = new WineTitleInfo(getWineLocalTitle(info.pageId.pageTitle, this.locale));
      return {
        id: w.id,
        title: titleInfo.getDynamicLabel()
      };
    });
  }

  private getInitialFilter (): FilterValue {
    let allowed = this.props.data.wines.frontmatter.wines.map(w => w.id);
    if (this.props.location && this.props.location.search) {
      const params = queryString.parse(this.props.location.search);

      if (params.wine && allowed.includes(params.wine as string)) {
        allowed = [params.wine as string];
      }
    }
    return { wineIds: allowed, textFilter: '' };
  }
  
  private onSort(sortId: SORTBY): void {
    const newOrder = this.onSortHelper(sortId, this.state.recipes);
    this.setState({ recipes: newOrder }, () => {
      if (this.filteredSectionRef.current)
        this.filteredSectionRef.current.update();
    });
  }

  private onSortHelper (sortId: SORTBY, recipes: RecipeData[]): RecipeData[] {
    const popularitySort = (a: RecipeData, b: RecipeData) => b.frontmatter.popularity - a.frontmatter.popularity;
    const alphabeticalSort = (a: RecipeData, b: RecipeData) => {
      const titleA = a.frontmatter.title;
      const titleB = b.frontmatter.title;
      return titleA > titleB ? 1 : titleB > titleA ? -1 : 0;
    };
    return recipes.sort(sortId === SORTBY.POPULARITY ? popularitySort : alphabeticalSort);
  }

  private renderFilters (onChange: RecipeFilterOnChangeCallback, widgetRef: React.RefObject<RecipeFiltersImplHandlers>) {
    return (
      <RecipeFilters
        onChange = { onChange }
        onSort = { this.onSort }
        ref = { widgetRef }
        wines = { this.wineChoices }
        wakeupValue = { this.getInitialFilter() }
      />
    );
  }

  private filterMethod (filter: FilterValue, child: React.ReactNode): boolean {
    const recipeSectionProps = (child as ReactElement<RecipeSectionProps>).props;
    const wineFilter = (wineIds: string[]) => {
      const filtered = filter.wineIds;
      const intersect = wineIds.filter(w => filtered.includes(w));
      return intersect.length > 0;
    };

    const textFilter = (props: RecipeSectionProps) => {
      const title = props.title.toLowerCase();
      const searchText = filter.textFilter ? filter.textFilter.toLowerCase() : null;
      if (!searchText) {
        return true;
      }
      if (title.indexOf(searchText) !== -1) {
        return true;
      } else {
        const text = props.rawText || '';
        return text.indexOf(searchText) !== -1;
      }
    };

    if (wineFilter(recipeSectionProps.wineIdMatches)) {
      if (filter.textFilter) {
        return textFilter(recipeSectionProps);
      }
      return true;
    }
    return false;
  }

  private getSEO (location: LocationInfo): PageSEO {
    const seo = PageSEO.fromLocation(location);
    seo.isDisableIndex = true;
    return seo;
  }

  render () {
    const data = this.props.data;
    const localPageInfo = getLocalizedValue(pageInfoByLocale(data.page), this.locale);
    updateSEO(this.seo, localPageInfo);
  
    return (
      <Layout locale={this.locale} location={this.props.location} title = { localPageInfo.title } seo = { this.seo }>
        <InterractiveSection title = { localPageInfo.title } >
          <>
            <div className = "w3-margin w3-padding-16" dangerouslySetInnerHTML={{ __html: data.intro.html }} />
            <FilteredSectionForwarded
              filtersRenderMethod={this.renderFilters}
              filterAppliesMethod={this.filterMethod}
              emptyMessage = { this.labels.sectionRecipesNoResults }
              ref = { this.filteredSectionRef }
            >
              {
                this.state.recipes.map(r => {
                  return <RecipeSection
                    title = { r.frontmatter.title }
                    titleimage = { r.frontmatter.titleimage.childImageSharp.gatsbyImageData }
                    wineIdMatches = { r.frontmatter.pairing }
                    recipeStats = {
                      new RecipeStats(
                        r.frontmatter.info.prepmins ? new Time(0, r.frontmatter.info.prepmins) : null,
                        r.frontmatter.info.mins ? new Time(0, r.frontmatter.info.mins) : null,
                        r.frontmatter.info.servings)
                    }
                    recipeLink = { localizeUrl(`/recipes${r.fields.slug}`) }
                    excerpt = { r.excerpt }
                    key = { r.fields.slug }
                    rawText = { r.rawMarkdownBody }
                  />;
                })
              }
            </FilteredSectionForwarded>
          </>
        </InterractiveSection>
      </Layout>
    );
  }
}

interface LocalRecipe {
  node: {
    frontmatter: {
      title: string
    },
    excerpt: string,
    rawMarkdownBody: string,
    fields: {
      slug: string,
      locale: string
    }
  }
}

interface RecipesData {
  recipes: {
    edges: {
      node: {
        frontmatter: {
          titleimage: {
            childImageSharp: {
              gatsbyImageData: IGatsbyImageData
            }
          },
          pairing: string[],
          info: {
            prepmins: number,
            mins: number,
            servings: number
          },
          popularity: number
        },
        fields: {
          slug: string
        }
      }
    }[]
  },
  localRecipes: {
    edges: LocalRecipe[]
  },
  wines: {
    frontmatter: {
      wines: WineListData[]
    }
  },
  intro: {
    html: string
  },
  page: PageInfoData,
  labels: {
    nodes: AllLocalesLabels[]
  }
}

export default Recipes;

export const query = graphql`
query($locale: String!, $includeBottle: Boolean = false, $includeDescriptions: Boolean = false) {
  recipes : allMarkdownRemark(filter: {
    fields:{
      slug: {regex:"/\\/recipe-[^\\.]*\\//"}
    }
  }) {
    edges {
      node {
        frontmatter {
          titleimage {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 600)
            }
          }
          pairing
          info {
            prepmins
            mins
            servings
          }
          popularity
        }
        fields {
          slug
        }
      }
    }
  }
  localRecipes: allMarkdownRemark(filter: {
    fields: {
      slug: {regex: "^/recipe-.+/"}
      locale: {eq: $locale}
    }
  }) {
    edges {
      node {
        frontmatter {
          title
        }
        excerpt(pruneLength: 250)
        rawMarkdownBody
        fields {
          slug
          locale
        }
      }
    }
  }
  intro: markdownRemark(fields: {slug: {regex: "/recipes-intro\\.[a-z]{2}/"}, locale: {eq: $locale}}) {
    html
  }
  wines: markdownRemark(fields: {slug: {eq: "/wines-list/"}}) {
    frontmatter {
      wines {
        ...wineList
      }
    }
  }
  page: pageIndexYaml(yamlId : {eq: "recipes"}) {
    ...PageInfo
  }
  labels: allLabelsTranslatedYaml(filter: {name: {in: [
      "sectionRecipesNoResults"
  ]}}) {
    nodes {
      ...AllLocalesLabels
    }
  }
}`;
